import { Logo } from '../assets'
import { AnimatedScreenWrapper } from './AnimatedScreenWrapper/AnimatedScreenWrapper'

export interface ScreenWrapperProps {
  className: string
  children: React.ReactNode
}

export const ScreenWrapper = (props: ScreenWrapperProps) => {
  const { className, children } = props
  return (
    <div className={`full-screen ${className}`}>
      <div className="grid">
        <div className="logo-position">
          <AnimatedScreenWrapper>
            <Logo />
          </AnimatedScreenWrapper>
        </div>
        <div className="fixed-block">{children}</div>
      </div>
    </div>
  )
}
