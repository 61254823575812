import { forwardRef, InputHTMLAttributes, useState } from 'react'
import { motion } from 'framer-motion'

// Styles
import './input.scss'

// Icons
import { HidePassword, ShowPassword } from '../../assets'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string | null
  inputClassNames?: string | null
  customBackgroundColor?: string
}

const animations = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: -10 },
  exit: { opacity: 0, y: 0 },
}

type Ref = HTMLInputElement

export const Input = forwardRef<Ref, InputProps>((props, ref) => {
  const { errorMessage, inputClassNames, customBackgroundColor, ...inputProps } = props
  const [toggleShowPasswordIcon, setToggleShowPasswordIcon] = useState(true)
  return (
    <div className="input-container">
      <input
        ref={ref}
        className={`input-container__input ${errorMessage ? 'error' : ''} ${inputClassNames ? inputClassNames : ''}`}
        {...inputProps}
        type={inputProps.type === 'password' ? (toggleShowPasswordIcon ? 'password' : 'text') : inputProps.type}
        style={{
          paddingRight: inputProps.type === 'password' ? 55 : 0,
          paddingLeft: inputProps.type === 'password' ? 55 : 0,
          backgroundColor: customBackgroundColor ? customBackgroundColor : '',
        }}
      />
      {inputProps.type === 'password' && (
        <button
          type="button"
          className="input-container__icon"
          onClick={() => setToggleShowPasswordIcon(!toggleShowPasswordIcon)}
        >
          {!toggleShowPasswordIcon ? <ShowPassword /> : <HidePassword />}
        </button>
      )}
      {errorMessage && (
        <motion.p
          variants={animations}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.4 }}
          style={{ width: '100%' }}
          className="input-container__error-message"
        >
          {errorMessage}
        </motion.p>
      )}
    </div>
  )
})
