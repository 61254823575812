import { useState } from 'react'
import { motion } from 'framer-motion'

import './sliders.scss'

// Icons
import { Arrow, CloseIcon } from '../../assets'

export interface SlidersProps {
  sliders: any[]
  sliderIndexProp: number
  onHandleClose?: () => void
}

const animations = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: -10 },
  exit: { opacity: 0, y: 0 },
}

export const Sliders = (props: SlidersProps) => {
  const { sliders, sliderIndexProp = 0, onHandleClose } = props
  const [sliderIndex, setSliderIndex] = useState(sliderIndexProp)
  const lastItemIndex = sliders.length - 1
  return (
    <div className="sliders">
      <div className="sliders__close-button">
        <button onClick={onHandleClose}>
          <CloseIcon />
        </button>
      </div>
      <motion.div
        variants={animations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.4 }}
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '82%',
        }}
      >
        <div className="sliders__background-image" style={{ backgroundImage: `url(${sliders[sliderIndex].slide})` }} />
        <div className="sliders__content">
          <h5
            className="sliders__title"
            dangerouslySetInnerHTML={{ __html: sliders[sliderIndex].slideTitle.replace(/\n/g, '<br />') }}
          />
          <p className="sliders__description">{sliders[sliderIndex].description}</p>
        </div>
      </motion.div>
      <div className="sliders__button-container">
        {sliders.map((item, index) => (
          <button
            key={item.id}
            className={`slider-button${index === sliderIndex ? '__active' : '__inactive'}`}
            // onClick={() => setSliderIndex(index)}
          />
        ))}
      </div>

      <button className="sliders__prev" disabled={sliderIndex === 0} onClick={() => setSliderIndex(sliderIndex - 1)}>
        <Arrow />
      </button>
      <button
        className="sliders__next"
        disabled={lastItemIndex === sliderIndex}
        onClick={() => setSliderIndex(sliderIndex + 1)}
      >
        <Arrow />
      </button>
    </div>
  )
}
