import axios from 'axios'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'

// Styles
import './newPassword.scss'

// Utils
import { developmentUrl } from '../../utils/developmentUrl'

export const NewPasswordScreen = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, watch } = useForm()

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)

    const state = location.state as { otp: string; email: string }

    const fields = {
      email: state.email,
      otp: state.otp,
      newPassword: data.newPassword,
    }

    await axios
      .post(developmentUrl('users/password'), fields)
      .then(() => {
        setErrorMessage(null)
        setLoading(false)
        navigate('/log-in')
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message)
        setLoading(false)
      })
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading])

  const watchConfirmPassword = watch('confirmPassword')

  return (
    <ScreenWrapper className="new-password-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">Now, please enter your new password</h5>
            {errorMessage && (
              <AnimatedScreenWrapper>
                <p className="error-message">{errorMessage}</p>
              </AnimatedScreenWrapper>
            )}
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              minLength: 6,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="password"
                placeholder="New password"
                autoComplete="new-password"
                onChange={onChange}
                value={value || ''}
                errorMessage={error ? 'Length must be between 6 and 50' : null}
              />
            )}
            name="newPassword"
          />

          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              minLength: 6,
              validate: (value) => {
                console.log(watchConfirmPassword === value)
                if (!watchConfirmPassword === value) return 'Please enter the correct current password'
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="password"
                placeholder="New password"
                autoComplete="new-password"
                onChange={onChange}
                value={value || ''}
                errorMessage={!!error ? (error.message ? error.message : 'Length must be between 6 and 50') : null}
              />
            )}
            name="confirmPassword"
          />

          <Button disabled={!!errorMessage?.length || loading} type="submit">
            SUBMIT
          </Button>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
