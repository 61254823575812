import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'
import Modal from '../../components/Modal/Modal'
import { Loader } from '../../components/Loader/Loader'

// Svg
import { AngleRight, Logo } from '../../assets'

// Styles
import './manageSubscriptions.scss'
import { developmentUrl } from '../../utils/developmentUrl'

// Context
import { TokensType } from '../../context/RefreshTokenProvider'

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage'

// Utils
import { getRefreshToken } from '../../utils/refreshToken'
import { saveTokens } from '../../utils/saveTokens'

export const ManageSubscriptions = () => {
  let navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [sessionStorageToken] = useLocalStorage<TokensType | null>('sessionStorage.tokens', null)
  const [accessTokenState, setAccessTokenState] = useState<null | string>(null)
  const [loading, setLoading] = useState(false)

  const [show, setShow] = useState<boolean>(false)
  const [iFrameIsLoading, setIFrameIsLoading] = useState<boolean>(true)
  const [chargebeeSubscribeUrl, setChargebeeSubscribeUrl] = useState<string | null>(null)
  const [urlLoading, setUrlLoading] = useState(false)

  useEffect(() => {
    if (!sessionStorageToken) {
      navigate('/')
    }
  }, [sessionStorageToken, navigate])

  const onManageSubscriptions = async () => {
    const url = `payments/portal-session`
    let currentDate = new Date()

    if (!!sessionStorageToken?.accessToken) {
      if (sessionStorageToken.expiring < currentDate.getTime() || !accessTokenState) {
        const tokens = await getRefreshToken(sessionStorageToken.refreshToken)

        // Get header
        const accessToken = tokens.headers['x-access-token']

        // // Save tokens in local storage
        await saveTokens(tokens)

        // Save in state
        setAccessTokenState(accessToken)

        await axios
          .get(developmentUrl(url), {
            headers: { 'X-ACCESS-TOKEN': accessToken },
          })
          .then((response) => {
            const location = response.headers['location']
            setChargebeeSubscribeUrl(location)
            if (location) {
              setShow(true)
              setUrlLoading(false)
              setErrorMessage(null)
            }
          })
          .catch((error) => {
            setUrlLoading(false)
            setErrorMessage(error.response.data.message)
          })
      } else {
        await axios
          .get(developmentUrl(url), {
            headers: { 'X-ACCESS-TOKEN': accessTokenState ? accessTokenState : sessionStorageToken.accessToken },
          })
          .then((response) => {
            const location = response.headers['location']
            setChargebeeSubscribeUrl(location)
            if (location) {
              setShow(true)
              setUrlLoading(false)
              setErrorMessage(null)
            }
          })
          .catch((error) => {
            setUrlLoading(false)
            setErrorMessage(error.response.data.message)
          })
      }
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
      setErrorMessage(null)
      setUrlLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading, urlLoading])

  const onHandleCloseIframeModal = () => {
    setShow(false)
    setIFrameIsLoading(true)
  }

  const onHandleLogOut = () => {
    localStorage.removeItem('sessionStorage.tokens')
    navigate('/')
  }

  return (
    <div className="full-screen manage-subscriptions">
      <AnimatedScreenWrapper>
        <div className="grid">
          <div className="logo-position">
            <Logo />
          </div>

          <Rectangle>
            <div>
              <h5 className="manage-subscriptions__title">What would you like to do?</h5>
              {errorMessage && (
                <AnimatedScreenWrapper>
                  <p className="error-message">{errorMessage}</p>
                </AnimatedScreenWrapper>
              )}
            </div>

            <button className="manage-subscriptions__buttons" onClick={onManageSubscriptions} type="button">
              Manage your membership <AngleRight />
            </button>
            <button className="manage-subscriptions__buttons" onClick={onManageSubscriptions} type="button">
              Update your payment <AngleRight />
            </button>
            <button className="manage-subscriptions__buttons" onClick={onHandleLogOut} type="button">
              Logout
            </button>
          </Rectangle>
        </div>
      </AnimatedScreenWrapper>

      <Modal onClose={onHandleCloseIframeModal} show={show} id="manage_subscription_modal" modalClassName="rounded-10">
        {chargebeeSubscribeUrl && (
          <>
            {iFrameIsLoading && (
              <div className="iframe-loading">
                <Loader />
              </div>
            )}

            {chargebeeSubscribeUrl && (
              <iframe
                onLoad={() => setIFrameIsLoading(false)}
                title="Subscribe on chargebee"
                src={chargebeeSubscribeUrl}
                className="iframe"
              />
            )}
          </>
        )}
      </Modal>
    </div>
  )
}
