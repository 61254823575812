import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'

// Styles
import './email.scss'

// Utils
import { validateEmail } from '../../utils/validateEmail'
import { developmentUrl } from '../../utils/developmentUrl'

export const ForgotPasswordEmailScreen = () => {
  let navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm()

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    await axios
      .post(developmentUrl(`users/email?email=${data.email}`))
      .then(() => {
        setErrorMessage(null)
        setLoading(false)
        navigate('/one-time-password', { state: data.email })
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message)
        setLoading(false)
      })
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading])

  return (
    <ScreenWrapper className="forgot-password-email-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">What’s your email?</h5>
            {errorMessage && (
              <AnimatedScreenWrapper>
                <p className="error-message">{errorMessage}</p>
              </AnimatedScreenWrapper>
            )}
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                if (!validateEmail(value)) return 'Please enter valid email'
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  type="email"
                  placeholder="Your email"
                  autoComplete="email"
                  onChange={onChange}
                  value={value || ''}
                  errorMessage={!!error ? (error.message ? error.message : 'Your email is required') : null}
                />
              )
            }}
            name="email"
          />

          <Button disabled={!!errorMessage?.length || loading} type="submit">
            Next
          </Button>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
