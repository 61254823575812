import axios from 'axios'
import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'

// Styles
import './email.scss'

// Utils
import { validateEmail } from '../../utils/validateEmail'
import { developmentUrl } from '../../utils/developmentUrl'

export const EmailScreen = () => {
  let navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control, watch } = useForm()
  const email = watch('email')

  const onSubmit = handleSubmit((data) => {
    if (!errorMessage?.length) {
      navigate('/first-name', { state: data })
    }
  })

  const onHandleFocus = useCallback(async (e: any) => {
    let email = e.target.value
    if (validateEmail(email)) {
      setLoading(true)
      await axios
        .get(developmentUrl(`users/email?email=${email}`))
        .then(() => {
          // console.log(response, 'response')
          setErrorMessage(null)
          setLoading(false)
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message)
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading])

  return (
    <ScreenWrapper className="email-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">Enter your email and password</h5>
            {errorMessage && (
              <AnimatedScreenWrapper>
                <p className="error-message">{errorMessage.replace(email, '')}</p>
              </AnimatedScreenWrapper>
            )}
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                if (!validateEmail(value)) return 'Please enter valid email'
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  type="email"
                  placeholder="Your email"
                  autoComplete="email"
                  onChange={onChange}
                  value={value || ''}
                  onBlur={onHandleFocus}
                  onKeyDown={() => setErrorMessage(null)}
                  errorMessage={!!error ? (error.message ? error.message : 'Your email is required') : null}
                />
              )
            }}
            name="email"
          />

          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              minLength: 6,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                onChange={onChange}
                value={value || ''}
                errorMessage={error ? 'Length must be between 6 and 50' : null}
              />
            )}
            name="password"
          />

          <Button disabled={!!errorMessage?.length || loading} type="submit">
            next
          </Button>
          <p className="log-in">
            Already have an account?{' '}
            <button type="button" className="login-button" onClick={() => navigate('/log-in')}>
              Log In
            </button>
          </p>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
