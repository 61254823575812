import { ReactNode } from 'react'
import { motion } from 'framer-motion'

export interface AnimatedScreenWrapperProps {
  children: ReactNode
}

const animations = {
  initial: { opacity: 0, y: 0 },
  animate: { opacity: 1, y: -10 },
  exit: { opacity: 0, y: 0 },
}

export const AnimatedScreenWrapper = (props: AnimatedScreenWrapperProps) => {
  const { children } = props
  return (
    <motion.div
      variants={animations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.4 }}
      style={{ width: '100%' }}
    >
      {children}
    </motion.div>
  )
}
