import { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { TokensType } from '../context/RefreshTokenProvider'
import { storageTokensKey } from './keys'

export const saveTokens = (response: AxiosResponse): TokensType => {
  const refreshToken = response.headers['x-refresh-token']
  const accessToken = response.headers['x-access-token']

  const token = jwtDecode(accessToken) as any

  const tokens: TokensType = {
    refreshToken,
    expiring: token.exp * 1000,
    accessToken,
  }

  localStorage.setItem(storageTokensKey, JSON.stringify(tokens))
  return tokens
}
