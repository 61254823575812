// Svg
import { Inspiration, Access, Skips, Unlock, Support } from '../../assets'

import inspirationImage from '../../assets/images/inspiration.png'
import accessImage from '../../assets/images/access.png'
import unlockImage from '../../assets/images/unlock.png'
import skipsImage from '../../assets/images/skips.png'
import supportImage from '../../assets/images/support.png'

// Slider Image
import accessSlider from '../../assets/images/sliders/access.png'
import inspirersSlider from '../../assets/images/sliders/inspirers.png'
import unlockSlider from '../../assets/images/sliders/unlock.png'
import skipsSlider from '../../assets/images/sliders/skips.png'
import supportSlider from '../../assets/images/sliders/support.jpg'

export const whatYouGet = [
  {
    id: 1,
    title: 'Access To All Presence \n Premium Content',
    slideTitle: 'Access to all Premium \n Presence Content',
    description: 'The Presence Membership \n unlocks exclusive access \n to our whole library',
    icon: <Inspiration />,
    backgroundColor: `url(${inspirationImage})`,
    slide: accessSlider,
    className: 'remove-space',
  },
  {
    id: 2,
    title: 'Access A World \n Of Inspirers',
    slideTitle: 'Access A World \n of Inspiring People',
    description: 'Find who inspires you \n the most and enjoy the \n diversity of their ideas',
    icon: <Access />,
    backgroundColor: `url(${accessImage})`,
    slide: inspirersSlider,
    className: null,
  },
  {
    id: 3,
    title: 'Unlock Your Favorite \n Rays & Raylists',
    slideTitle: 'Unlock Your Favorite \n Rays & Raylists',
    description: 'Save every Ray and \n Raylist you love to enjoy \n them at any time',
    icon: <Unlock />,
    backgroundColor: `url(${unlockImage})`,
    slide: unlockSlider,
    className: null,
  },
  {
    id: 4,
    title: 'Unlimited Skips',
    slideTitle: 'Unlock Unlimited \n Skips',
    description: 'The Presence Membership \n allows you to move through \n the Rays of each Raylist',
    icon: <Skips />,
    backgroundColor: `url(${skipsImage})`,
    slide: skipsSlider,
    className: null,
  },
  {
    id: 5,
    title: 'World Class Support',
    slideTitle: 'World Class \n Support',
    description:
      'Superior support from a team \n dedicated to the transformative \n capabilities of merging tech \n with mindfulness',
    icon: <Support />,
    backgroundColor: `url(${supportImage})`,
    slide: supportSlider,
    className: null,
  },
]
