import './subscriptionCard.scss'

export interface SubscriptionCardProps {
  type: string
  name?: string
  price: string | number
  duration?: string
  isActive: boolean
  color: string
  note?: string
  noteColor: string
  onHandleSelectSubscription: () => void
}

export const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { type, price, noteColor, note, isActive, onHandleSelectSubscription, color } = props

  const borderStyle = {
    borderLeft: isActive ? 'none' : `5px solid ${color}`,
    borderRight: isActive ? 'none' : `5px solid ${color}`,
    borderBottom: isActive ? '5px solid transparent' : `5px solid ${color}`,
  }

  return (
    <div className={`subscription-card`} role="button" onClick={onHandleSelectSubscription} style={{ ...borderStyle }}>
      <p className="subscription-card__type" style={{ backgroundColor: color }}>
        {type}
      </p>

      <div className="subscription-card__block">
        {/* <p className="subscription-card__name">{name}</p> */}
        <p
          className="subscription-card__price"
          style={{ color: isActive ? 'var(--description-color)' : 'var(--rich-black-blue-color)' }}
        >
          ${price}
        </p>
        {note && (
          <p className="subscription-card__note" style={{ color: noteColor }}>
            {note}
          </p>
        )}
        {/* <p className="subscription-card__duration">/{duration}</p> */}
      </div>
    </div>
  )
}
