import axios from 'axios'
import { developmentUrl } from './developmentUrl'

export const getRefreshToken = async (refreshToken: string) => {
  const response = await axios.put(
    developmentUrl('auth'),
    { headers: { 'Content-Type': 'application/json' } },
    {
      headers: {
        'X-REFRESH-TOKEN': refreshToken,
      },
    }
  )

  return response
}
