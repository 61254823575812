import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios from 'axios'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'

// styles
import './firstName.scss'

// Utils
import { saveTokens } from '../../utils/saveTokens'
import { developmentUrl } from '../../utils/developmentUrl'

interface EmailScreenState {
  email: string
  password: string
}

export const FirstNameScreen = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const { register, handleSubmit } = useForm()
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [firstNameRequired, setFirstNameRequired] = useState(false)

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true)
    const state = location.state as EmailScreenState

    const lastName = !!data.lastName ? { lastName: data.lastName } : undefined

    if (state) {
      const body = {
        ...state,
        person: {
          firstName: data.firstName,
          ...lastName,
        },
      }

      await axios
        .post(developmentUrl('users'), body)
        .then((response) => {
          if (response.status === 201) {
            saveTokens(response)
            navigate('/first-verification', { state: { email: state.email } })
            setIsLoading(false)
            setErrorMessage(null)
          }
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message)
          setIsLoading(false)
        })
    } else {
      navigate('/email')
    }
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorMessage(null)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [errorMessage])

  const onHandleResetError = (e: any) => {
    let value = e.target.value

    if (!!value.length) {
      setFirstNameRequired(false)
    } else {
      setFirstNameRequired(true)
    }
  }

  return (
    <ScreenWrapper className="first-name-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">What's your name?</h5>
            {firstNameRequired && <p className="error-message">Your first name is required</p>}
            {errorMessage && (
              <AnimatedScreenWrapper>
                <p className="error-message">{errorMessage}</p>
              </AnimatedScreenWrapper>
            )}
          </div>
          <Input
            type="text"
            placeholder="first name"
            {...register('firstName', { required: true })}
            onBlur={onHandleResetError}
          />
          <Input type="text" placeholder="last name" {...register('lastName')} />

          <Button disabled={isLoading} type="submit">
            submit
          </Button>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
