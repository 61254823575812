import React from 'react'
import { createPortal } from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import './modal.scss'

// Hooks
import usePortal from '../../hooks/usePortal'

// Icons
import { ReactComponent as Close } from '../../assets/icons/close.svg'

interface ModalProps {
  id: string
  onClose: () => void
  children: React.ReactNode
  show: boolean
  modalClassName?: string
  hideCloseButton?: boolean
}

const Modal = (props: ModalProps) => {
  const { id, onClose, children, show, modalClassName, hideCloseButton } = props
  const target = usePortal(id)
  const nodeRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'initial'
    }
  }, [show])

  React.useEffect(() => {
    const closeOnEscapeKeyDown = (e: any) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose()
      }
    }

    document.body.addEventListener('keydown', closeOnEscapeKeyDown)
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [onClose])

  return createPortal(
    <CSSTransition in={show} nodeRef={nodeRef} unmountOnExit timeout={{ enter: 0, exit: 300 }}>
      <div className="s-modal" onClick={onClose} ref={nodeRef}>
        <div className={`s-modal-content ${modalClassName ? modalClassName : ''}`} onClick={(e) => e.stopPropagation()}>
          {!hideCloseButton && (
            <button id="close-button-id" className="s-modal-close" onClick={onClose}>
              <Close />
            </button>
          )}
          <div>{children}</div>
        </div>
      </div>
    </CSSTransition>,
    target
  )
}

export default Modal
