import { useLayoutEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import WebFont from 'webfontloader'

import './styles/app.scss'

// Screens
import {
  // WelcomeScreen,
  EmailScreen,
  FirstNameScreen,
  VerificationFirstScreen,
  // VerificationSecondScreen,
  SubscriptionScreen,
  DoneScreen,
  LogInScreen,
  ManageSubscriptions,
  // Forgor password
  ForgotPasswordEmailScreen,
  OneTimePasswordScreen,
  NewPasswordScreen,
} from './screens'

// Context
import { TokenProvider } from './context/RefreshTokenProvider'

function App(): JSX.Element {
  const location = useLocation()

  useLayoutEffect(() => {
    WebFont.load({
      google: {
        families: ['Poppins:700,600,500'],
      },
    })
  }, [])

  return (
    <TokenProvider>
      <AnimatePresence exitBeforeEnter>
        <Routes key={location.pathname} location={location}>
          {/* <Route path="/" element={<WelcomeScreen />} /> */}
          <Route path="/" element={<EmailScreen />} />
          <Route path="first-name" element={<FirstNameScreen />} />
          <Route path="first-verification" element={<VerificationFirstScreen />} />
          {/* <Route path="second-verification" element={<VerificationSecondScreen />} /> */}
          <Route path="subscriptions" element={<SubscriptionScreen />} />
          <Route path="log-in" element={<LogInScreen />} />
          <Route path="done" element={<DoneScreen />} />
          <Route path="manage-subscriptions" element={<ManageSubscriptions />} />
          {/* Forgot password  */}
          <Route path="forgot-password-email" element={<ForgotPasswordEmailScreen />} />
          <Route path="one-time-password" element={<OneTimePasswordScreen />} />
          <Route path="new-password" element={<NewPasswordScreen />} />
        </Routes>
      </AnimatePresence>
    </TokenProvider>
  )
}

export default App
