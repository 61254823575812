// import { useEffect } from 'react'
// import React from 'react'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { ScreenWrapper } from '../../components/ScreenWrapper'

// Svg
import { Email } from '../../assets'

// Styles
import './verification.scss'
import { useLocation } from 'react-router-dom'

type EmailState = {
  email: string
}

export const VerificationFirstScreen = () => {
  const location = useLocation()
  const state = location.state as EmailState

  return (
    <ScreenWrapper className="verification-bg">
      <Rectangle>
        <h5 className="secondary-title">Verify your email</h5>
        <p className="description-thin">
          We've sent your verification <br /> email to <span className="info">{state?.email}</span>.
        </p>
        <p className="description-thin">Please check your inbox!</p>
        <div className="center">
          <Email />
        </div>
      </Rectangle>
    </ScreenWrapper>
  )
}
