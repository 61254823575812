// Icons
import { ReactComponent as MainLogo } from './logo.svg'

// Icons
import { ReactComponent as AngleRight } from './icons/angle-right.svg'
import { ReactComponent as Email } from './icons/email.svg'
import { ReactComponent as ExtractedEmail } from './icons/extracted-email.svg'
import { ReactComponent as Inspiration } from './icons/inspiration.svg'
import { ReactComponent as Access } from './icons/access.svg'
import { ReactComponent as Skips } from './icons/skips.svg'
import { ReactComponent as Unlock } from './icons/unlock.svg'
import { ReactComponent as LogoWithAppStore } from './icons/app-store.svg'
import { ReactComponent as Close } from './icons/close.svg'
import { ReactComponent as HidePassword } from './icons/hide-password.svg'
import { ReactComponent as ShowPassword } from './icons/show-password.svg'
import { ReactComponent as Plus } from './icons/plus.svg'
import { ReactComponent as Support } from './icons/support.svg'
import { ReactComponent as Arrow } from './icons/arrow.svg'
import { ReactComponent as AppleStore } from './icons/apple-store.svg'
import { ReactComponent as MainLogoWithoutText } from './icons/logo-without-text.svg'
import { ReactComponent as QuestionMark } from './icons/question-mark.svg'
import { ReactComponent as CloseIcon } from './icons/close-icon.svg'

const Logo = () => {
  return (
    <div className="center">
      <MainLogo width="146" height="100" />
    </div>
  )
}

const LogoWithoutText = () => {
  return (
    <div className="center">
      <MainLogoWithoutText className="logo-without-text" />
    </div>
  )
}

const AppStore = () => {
  return (
    <div className="center">
      <LogoWithAppStore width="190" height="160" />
    </div>
  )
}

export {
  Logo,
  AngleRight,
  Email,
  ExtractedEmail,
  Inspiration,
  Access,
  Skips,
  Unlock,
  AppStore,
  Close,
  HidePassword,
  ShowPassword,
  Plus,
  Support,
  Arrow,
  AppleStore,
  LogoWithoutText,
  QuestionMark,
  CloseIcon,
}
