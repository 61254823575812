import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

// Hooks
import { ConfigProvider, ConfigType } from './hooks/useConfig'

const config: ConfigType = {
  baseURL: process.env.REACT_APP_API_HOST as string,
}

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ConfigProvider config={config}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
