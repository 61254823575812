// import React from 'react'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { useLocation, useNavigate } from 'react-router-dom'
import { ScreenWrapper } from '../../components/ScreenWrapper'

// Svg
import { AppleStore } from '../../assets'

// Styles
import './done.scss'

const appleStoreButtonStyles = {
  backgroundColor: 'transparent',
  border: 0,
}

export const DoneScreen = () => {
  let navigate = useNavigate()
  const location = useLocation()

  const onHandleGoAppStore = () => {
    // console.log('go')
  }

  const monthPromoCode = location.state as string

  return (
    <ScreenWrapper className="done-bg">
      <Rectangle>
        <h5 className="main-title">Just one last step.</h5>
        <p className="description-thin">
          To connect your Presence to WiFi, <br /> download the app.
        </p>
        {/* <Button onClick={onHandleGoAppStore}>
              Go to app store <AngleRight />
            </Button> */}
        <button onClick={onHandleGoAppStore} style={appleStoreButtonStyles}>
          <AppleStore />
        </button>

        {monthPromoCode && (
          <button
            className="one-month-trial"
            type="button"
            onClick={() => navigate('/subscriptions', { state: 'PRESENCEONEMONTH' })}
          >
            Want a one month free trial?
          </button>
        )}
      </Rectangle>
    </ScreenWrapper>
  )
}
