import { ReactNode, ButtonHTMLAttributes } from 'react'

// Styles
import './button.scss'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

export const Button = (props: ButtonProps) => {
  const { children, ...buttonProps } = props
  return (
    <button className="button" {...buttonProps}>
      {children}
    </button>
  )
}
