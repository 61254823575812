import axios from 'axios'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'

// Styles
import './logIn.scss'

// Utils
import { validateEmail } from '../../utils/validateEmail'
import { developmentUrl } from '../../utils/developmentUrl'
import { saveTokens } from '../../utils/saveTokens'

export const LogInScreen = () => {
  let navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const { handleSubmit, control } = useForm()

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    const body = {
      email: data.email,
      password: data.password,
    }

    await axios
      .post(developmentUrl(`auth`), body)
      .then((response) => {
        const subscriptionInfo = response.data.subscriptionInfo
        saveTokens(response)
        setErrorMessage(null)
        setLoading(false)
        navigate('/subscriptions')

        if (!!subscriptionInfo.length) {
          navigate('/manage-subscriptions')
        } else {
          navigate('/subscriptions')
        }
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message)
        setLoading(false)
      })
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [loading, errorMessage])

  const onHandleForgetPassword = () => {
    navigate('/forgot-password-email')
  }

  return (
    <ScreenWrapper className="log-in-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">Let’s Log In</h5>
            {errorMessage && (
              <AnimatedScreenWrapper>
                <p className="error-message">{errorMessage}</p>
              </AnimatedScreenWrapper>
            )}
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                if (!validateEmail(value)) return 'Please enter valid email'
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  type="email"
                  placeholder="Your email"
                  autoComplete="email"
                  onChange={onChange}
                  value={value || ''}
                  onKeyDown={() => setErrorMessage(null)}
                  errorMessage={!!error ? (error.message ? error.message : 'Your email is required') : null}
                />
              )
            }}
            name="email"
          />

          <Controller
            control={control}
            rules={{
              required: true,
              maxLength: 50,
              minLength: 6,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                onChange={onChange}
                value={value || ''}
                errorMessage={error ? 'Length must be between 6 and 50' : null}
              />
            )}
            name="password"
          />

          <Button disabled={loading} type="submit">
            log in
          </Button>
          <button type="button" className="forgot-password" onClick={onHandleForgetPassword}>
            FORGOT PASSWORD?
          </button>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
