import { useLocation, useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Input } from '../../components/Input/Input'
import { Button } from '../../components/Button/Button'
import { ScreenWrapper } from '../../components/ScreenWrapper'

// Styles
import './oneTimePassword.scss'

export const OneTimePasswordScreen = () => {
  let navigate = useNavigate()
  const location = useLocation()

  const { handleSubmit, control } = useForm()

  const onSubmit = handleSubmit((data) => {
    const state = location.state as string
    navigate('/new-password', { state: { otp: data.otp, email: state } })
  })

  return (
    <ScreenWrapper className="one-time-password-bg">
      <form onSubmit={onSubmit}>
        <Rectangle>
          <div>
            <h5 className="secondary-title">Enter your One Time Password</h5>
          </div>
          <Controller
            control={control}
            rules={{
              required: true,
              minLength: 6,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  type="number"
                  placeholder="One Time Password"
                  onChange={onChange}
                  value={value || ''}
                  errorMessage={!!error ? 'This field is required' : null}
                />
              )
            }}
            name="otp"
          />

          <Button type="submit">Next</Button>
        </Rectangle>
      </form>
    </ScreenWrapper>
  )
}
