import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { storageTokensKey } from '../utils/keys'
import axios from 'axios'

// Utils
import { saveTokens } from '../utils/saveTokens'
import { getRefreshToken } from '../utils/refreshToken'
import { developmentUrl } from '../utils/developmentUrl'

// Hooks
import { useLocalStorage } from '../hooks/useLocalStorage'

// Components
import { Loader } from '../components/Loader/Loader'

export type SessionContextType = {}

export interface TokensType {
  refreshToken: string
  accessToken: string
  expiring: number
}

export const SessionContext = createContext<SessionContextType | undefined>(undefined)

export function useToken(): SessionContextType {
  const context = useContext(SessionContext)

  if (context === undefined) {
    throw new Error('useToken must be used inside TokenProvider.')
  }

  return context
}

export function TokenProvider(props: any) {
  const { Provider } = SessionContext
  let navigate = useNavigate()
  const location = useLocation()
  let [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [sessionStorageToken] = useLocalStorage<TokensType | null>('sessionStorage.tokens', null)

  let shortLiveToken = searchParams.get('token')

  const refreshTokens = useCallback(async (tokens: TokensType): Promise<TokensType> => {
    const response = await getRefreshToken(tokens.refreshToken)

    return saveTokens(response)
  }, [])

  const isAccessTokenAboutToExpire = useCallback((tokens: TokensType): boolean => {
    let currentDate = new Date()
    if (tokens.expiring < currentDate.getTime()) {
      // console.log('Expired token')
      return true
    }

    // console.log('Valid token')
    return false
  }, [])

  const fetchMe = useCallback(
    async (tokens: TokensType) => {
      if (!['done', 'first-verification'].includes(location.pathname.replace('/', ''))) {
        await axios
          .get(developmentUrl(`users/my`), {
            headers: { 'X-ACCESS-TOKEN': tokens.accessToken },
          })
          .then((response) => {
            const subscriptionInfo = response.data.subscriptionInfo
            if (!!subscriptionInfo.length) {
              navigate('/manage-subscriptions')
            } else {
              navigate('/subscriptions')
            }
          })
          .catch(() => navigate('/subscriptions'))
      }
    },
    [navigate, location.pathname]
  )

  const loadSession = useCallback(async () => {
    const localStorageTokens = localStorage.getItem(storageTokensKey)

    if (!localStorageTokens) {
      setLoading(false)
      return
    }
    setLoading(true)

    let tokens = JSON.parse(localStorageTokens) as TokensType

    if (isAccessTokenAboutToExpire(tokens)) {
      tokens = await refreshTokens(tokens)
    }

    fetchMe(tokens)
    setLoading(false)
  }, [refreshTokens, isAccessTokenAboutToExpire, fetchMe])

  const saveShortLiveToken = useCallback(async () => {
    setLoading(true)
    await axios
      .get(developmentUrl(`auth/short-live-token?shortLiveToken=${shortLiveToken}`))
      .then(async (response) => {
        const tokens = await saveTokens(response)
        await fetchMe(tokens)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [shortLiveToken, fetchMe])

  useEffect(() => {
    if (!!sessionStorageToken) {
      setLoading(true)
      loadSession()
    }
  }, [loadSession, sessionStorageToken, navigate])

  useEffect(() => {
    if (shortLiveToken) {
      setLoading(true)
      saveShortLiveToken()
    }
  }, [shortLiveToken, saveShortLiveToken])

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Loader />
      </div>
    )
  }

  return <Provider {...props} value={null} />
}
