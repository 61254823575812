export const subscriptions = [
  {
    id: '1',
    chargebeePlanId: 'Monthly-Subscription-Default-USD-Yearly',
    type: 'YEARLY',
    name: `annual \n membership`,
    price: '60',
    duration: 'year',
    color: 'var(--dark-yellow-color)',
    note: '33% Discount',
    noteColor: 'var(--dark-yellow-color)',
  },
  {
    id: '2',
    chargebeePlanId: 'Monthly-Subscription-Default-USD-Monthly',
    type: 'MONTHLY',
    name: `Monthly \n Membership`,
    price: '6',
    duration: 'month',
    color: 'var(--dark-blue-color)',
    note: 'No commitment',
    noteColor: 'var(--light-gray-color)',
  },
]
