import { ReactNode } from 'react'
import { AnimatedScreenWrapper } from '../AnimatedScreenWrapper/AnimatedScreenWrapper'

import './rectangle.scss'

export interface RectangleProps {
  children: ReactNode
  isTransparent?: boolean
  customBackgroundColor?: string
  withoutBoxShadow?: boolean
}

export const Rectangle = (props: RectangleProps) => {
  const { children, isTransparent, customBackgroundColor, withoutBoxShadow } = props
  const styles = {
    boxShadow: isTransparent ? 'none' : !withoutBoxShadow ? '0px 4px 44px rgba(64, 52, 38, 0.3)' : 'none',
    backgroundColor: isTransparent
      ? 'transparent'
      : customBackgroundColor
      ? customBackgroundColor
      : 'var(--light-bg-color)',
  }
  return (
    <AnimatedScreenWrapper>
      <div className={`${isTransparent ? 'rectangle-transparent' : 'rectangle'}`} style={styles}>
        {children}
      </div>
    </AnimatedScreenWrapper>
  )
}
