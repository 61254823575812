import { useEffect, useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

// Components
import { Rectangle } from '../../components/Rectangle/Rectangle'
import { Button } from '../../components/Button/Button'
import { SubscriptionCard } from '../../components/SubscriptionCard/SubscriptionCard'
import { AnimatedScreenWrapper } from '../../components/AnimatedScreenWrapper/AnimatedScreenWrapper'
import Modal from '../../components/Modal/Modal'
import { Input } from '../../components/Input/Input'
import { Loader } from '../../components/Loader/Loader'

// Svg
import { LogoWithoutText, Plus, QuestionMark } from '../../assets'

// Styles
import './subscription.scss'

// Items
import { whatYouGet } from './items'
import { subscriptions } from './subscriptions'

// Hooks
import { useLocalStorage } from '../../hooks/useLocalStorage'

// Utils
import { developmentUrl } from '../../utils/developmentUrl'
import { getRefreshToken } from '../../utils/refreshToken'
import { saveTokens } from '../../utils/saveTokens'

// Context Types
import { TokensType } from '../../context/RefreshTokenProvider'

// Local components
import { Sliders } from './Sliders'

export const SubscriptionScreen = () => {
  let navigate = useNavigate()
  const location = useLocation()

  const promoCodeFromDoneScreen = location.state as string

  const [subscriptionPlanId, setSubscriptionPlanId] = useState(subscriptions[0].chargebeePlanId)
  const [promoCode, setPromoCode] = useState(promoCodeFromDoneScreen ? promoCodeFromDoneScreen : '')
  const [show, setShow] = useState<boolean>(false)
  const [showSliderModal, setShowSliderModal] = useState<boolean>(false)
  const [iFrameIsLoading, setIFrameIsLoading] = useState<boolean>(true)
  const [showPromoModal, setShowPromoModal] = useState<boolean>(false)
  const [sliderIndexProp, setSliderIndexProp] = useState<number>(0)
  const [chargebeeSubscribeUrl, setChargebeeSubscribeUrl] = useState<string | null>(null)
  const [sessionStorageToken] = useLocalStorage<TokensType | null>('sessionStorage.tokens', null)
  const [urlLoading, setUrlLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [accessTokenState, setAccessTokenState] = useState<null | string>(null)

  useEffect(() => {
    if (!sessionStorageToken) {
      navigate('/')
    }
  }, [sessionStorageToken, navigate])

  const onHandleSubmit = useCallback(async () => {
    setUrlLoading(true)
    const url = `payments/checkout?planId=${subscriptionPlanId}${promoCode ? `&couponId=${promoCode}` : ''}`
    let currentDate = new Date()

    if (!!sessionStorageToken?.accessToken) {
      if (sessionStorageToken.expiring < currentDate.getTime() || !accessTokenState) {
        // refresh token
        const tokens = await getRefreshToken(sessionStorageToken.refreshToken)

        // Get header
        const accessToken = tokens.headers['x-access-token']

        // // Save tokens in local storage
        await saveTokens(tokens)

        // Save in state
        setAccessTokenState(accessToken)

        // Get cargebee locations
        await axios
          .get(developmentUrl(url), {
            headers: { 'X-ACCESS-TOKEN': accessToken },
          })
          .then((response) => {
            const location = response.headers['location']
            setChargebeeSubscribeUrl(location)
            if (location) {
              setShow(true)
              setUrlLoading(false)
              setErrorMessage(null)
            }
          })
          .catch((error) => {
            setUrlLoading(false)
            setErrorMessage(error.response.data.message)
          })
      } else {
        await axios
          .get(developmentUrl(url), {
            headers: { 'X-ACCESS-TOKEN': accessTokenState ? accessTokenState : sessionStorageToken.accessToken },
          })
          .then((response) => {
            const location = response.headers['location']
            setChargebeeSubscribeUrl(location)
            if (location) {
              setShow(true)
              setUrlLoading(false)
              setErrorMessage(null)
            }
          })
          .catch((error) => {
            setUrlLoading(false)
            setErrorMessage(error.response.data.message)
          })
      }
    }
  }, [subscriptionPlanId, sessionStorageToken, setAccessTokenState, accessTokenState, promoCode])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setErrorMessage(null)
      setUrlLoading(false)
    }, 3000)

    return () => clearTimeout(timeout)
  }, [errorMessage, urlLoading])

  const onHandleSubscribe = async (subscription: any) => {
    if (subscriptionPlanId !== subscription.chargebeePlanId) {
      setSubscriptionPlanId(subscription.chargebeePlanId)
    }
  }

  const onHandleSelectSlide = (index: number) => {
    setShowSliderModal(true)
    setSliderIndexProp(index)
  }

  const onHandleCloseIframeModal = () => {
    setShow(false)
    setIFrameIsLoading(true)
  }

  const onHandleCreateBasicAccount = () => {
    navigate('/done', { state: 'PRESENCEONEMONTH' })
  }

  return (
    <div className="subscriptions-bg">
      <AnimatedScreenWrapper>
        <div className="subscription-page-grid">
          <div className="subscription-logo">
            <LogoWithoutText />
          </div>
          <section>
            <h3 className="subscription-main-title">Presence Premium</h3>
            <p className="subscription-main-description">
              As an early adopter, you’ve unlocked the <br /> entire world of Presence inspiration
            </p>
            <Rectangle customBackgroundColor="var(--white-bg-color)" withoutBoxShadow>
              {/* <h5 className="secondary-title">
                Awesome! You have a free membership trial. Please choose a plan for after it ends:
              </h5> */}
              <div className="subscription-promo-code">
                <Input
                  type="email"
                  placeholder="Enter your free trial code..."
                  inputClassNames="input-container__promo-code-placeholder"
                  onChange={(e) => setPromoCode(e.target.value)}
                  value={promoCode ? promoCode : ''}
                  customBackgroundColor="var(--custom-input-color)"
                />
                <button className="subscription-promo-code__answer" onClick={() => setShowPromoModal(true)}>
                  <QuestionMark />
                </button>
              </div>

              <h5 className="subscription-choose-trial-title">
                Please choose a plan for after <br /> your free trial ends:
              </h5>

              <div className="subscription-buttons">
                {subscriptions.map((subscription) => (
                  <SubscriptionCard
                    key={subscription.id}
                    type={subscription.type}
                    name={subscription.name}
                    price={subscription.price}
                    duration={subscription.duration}
                    color={subscription.color}
                    note={subscription.note}
                    noteColor={subscription.noteColor}
                    onHandleSelectSubscription={() => onHandleSubscribe(subscription)}
                    isActive={subscription.chargebeePlanId !== subscriptionPlanId}
                  />
                ))}
              </div>

              {/* <p className="promo-code-text">Enter your promo code on the next step</p> */}

              <Button disabled={urlLoading} onClick={onHandleSubmit}>
                next
              </Button>

              <p className="subscription-cancel-any-time-title">Cancel anytime.</p>
            </Rectangle>

            <h5 className="what-you-get-title">What you get</h5>
            <Rectangle isTransparent>
              {whatYouGet.map((item, index) => (
                <button
                  className="what-you-get-grid"
                  key={item.id}
                  style={{
                    backgroundImage: item.backgroundColor,
                  }}
                  onClick={() => onHandleSelectSlide(index)}
                >
                  <div className={item.className ? item.className : ''}>{item.icon}</div>
                  <div>
                    <h6
                      className="what-you-get-grid__title"
                      dangerouslySetInnerHTML={{ __html: item.title.replace(/\n/g, '<br />') }}
                    />
                  </div>
                  <div>
                    <Plus />
                  </div>
                </button>
              ))}
            </Rectangle>

            <div className="terms-and-privacy">
              <h5 className="terms-and-privacy__account">
                Just want the free{' '}
                <button onClick={onHandleCreateBasicAccount} className="terms-and-privacy__account-button">
                  basic account
                </button>
                ?
              </h5>
              <p className="terms-and-privacy__cancel">Recurring billing. Cancel anytime</p>
              <div className="terms-and-privacy__buttons">
                {/* <a href="#!">Restore Purchases</a>
                <span>•</span> */}
                <a href="https://discoverpresence.co/pages/terms" target="_blank" rel="noreferrer">
                  Terms
                </a>
                <span>•</span>
                <a href="https://discoverpresence.co/pages/privacy-policy" target="_blank" rel="noreferrer">
                  Privacy Policy
                </a>
              </div>
              <p className="terms-and-privacy__description">
                The subscription will automatically renew unless auto-renew is turned off at least 24 hours before the
                end of the current period. Your account will be charged for renewal within 24-hours prior to the end of
                the current period. You can go to your iTunes Account Settings to manage your subscription and turn off
                auto-renew. Your iTunes Account will be charged when the purchase is confirmed. Unused portion of free
                trial is forfeited after purchase.
              </p>
            </div>
          </section>
        </div>
      </AnimatedScreenWrapper>
      <Modal onClose={onHandleCloseIframeModal} show={show} id="subscription_modal" modalClassName="rounded-10">
        {chargebeeSubscribeUrl && (
          <>
            {iFrameIsLoading && (
              <div className="iframe-loading">
                <Loader />
              </div>
            )}

            <iframe
              onLoad={() => setIFrameIsLoading(false)}
              title="Subscribe on chargebee"
              src={chargebeeSubscribeUrl}
              className="iframe"
            />
          </>
        )}
      </Modal>

      <Modal
        onClose={() => setShowSliderModal(false)}
        show={showSliderModal}
        id="sliders_modal"
        modalClassName="rounded-45"
        hideCloseButton
      >
        <Sliders
          onHandleClose={() => setShowSliderModal(false)}
          sliders={whatYouGet}
          sliderIndexProp={sliderIndexProp}
        />
      </Modal>

      <Modal
        onClose={() => setShowPromoModal(false)}
        show={showPromoModal}
        id="question_promo-code_modal"
        modalClassName="rounded-25"
        hideCloseButton
      >
        <div className="promo-code-container">
          <h5 className="promo-code-container__title">Check your inbox</h5>
          <p className="promo-code-container__description">
            We sent your free trial code to the email used to purchase your Presence Display{' '}
          </p>
          <Button onClick={() => setShowPromoModal(false)}>Got it</Button>
        </div>
      </Modal>
    </div>
  )
}
